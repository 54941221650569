<template>
  <div id="DashboardMitraScreen">
    <v-container>
      <!-- Pemasukan/Pengeluaran -->
      <v-row>
        <v-col>
          <v-widget title="Pemasukan dan Pengeluaran" content-bg="white">
            <div slot="widget-content">
              <div>Bulan {{ currentMonth }}</div>
              <e-chart
                v-if="annualTransactionLoaded"
                :path-option="[
                  ['dataset.source', annualTransactionData],
                  ['color', [chartColors.blue, chartColors.green]],
                  ['legend.show', true],
                  ['xAxis.axisLabel.show', true],
                  ['yAxis.axisLabel.show', true],
                  ['grid.left', '2%'],
                  ['grid.bottom', '5%'],
                  ['grid.right', '3%'],
                  ['series[0].type', 'bar'],
                  ['series[0].areaStyle', {}],
                  ['series[0].smooth', true],
                  ['series[1].smooth', true],
                  ['series[1].type', 'bar'],
                  ['series[1].areaStyle', {}]
                ]"
                height="400px"
                width="100%"
              ></e-chart>
            </div>
          </v-widget>
        </v-col>
      </v-row>

      <!-- Penjualan Terbesar & Penjualan -->
      <v-row>
        <v-col class="col-12 col-lg-5">
          <v-widget title="Penjualan Terbesar" content-bg="white">
            <div slot="widget-content">
              <div>Jumlah Transaksi</div>
              <e-chart
                v-if="topTransactionLoaded"
                :path-option="[
                  ['dataset.source', topTransactionData],
                  ['legend.bottom', '0'],
                  [
                    'color',
                    ['#335EA6', '#F24848', '#38AC3D', '#FFE536', '#ABB1BB']
                  ],
                  ['xAxis.show', false],
                  ['yAxis.show', false],
                  ['series[0].type', 'pie'],
                  ['series[0].avoidLabelOverlap', true],
                  ['series[0].radius', ['0', '65%']],
                  ['series[0].label', false]
                ]"
                height="350px"
                width="100%"
              >
              </e-chart>
            </div>
          </v-widget>
        </v-col>
        <v-col class="col-12 col-lg-7">
          <v-card>
            <v-app-bar dense color="transparent">
              <v-toolbar-title>
                <h4>Penjualan</h4>
              </v-toolbar-title>
            </v-app-bar>

            <v-divider></v-divider>

            <v-card-text class="pa-0">
              <template>
                <v-data-table
                  :headers="penjualanHeaders"
                  :items="salesData"
                  class="elevation-0"
                  mobile-breakpoint="0"
                  hide-default-footer
                >
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td>
                        {{ props.item.transaction_numb }}
                      </td>
                      <td>
                        {{ formatRupiah(props.item.grand_total) }}
                      </td>
                      <td>
                        <v-chip
                          :color="getColor(props.item.transaction_method_id)"
                          dark
                          >{{ props.item.method }}</v-chip
                        >
                      </td>
                      <td>{{ props.item.customer_name }}</td>
                      <td>{{ props.item.transaction_date }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Tagihan Pembelian-->
      <v-row>
        <v-col class="col-12">
          <v-card>
            <v-app-bar dense color="transparent">
              <v-toolbar-title>
                <h4>Tagihan Pembelian</h4>
              </v-toolbar-title>
            </v-app-bar>

            <v-divider></v-divider>

            <v-card-text class="pa-0">
              <template>
                <v-data-table
                  :headers="purchaseBillHeaders"
                  :items="purchaseBillData"
                  class="elevation-0"
                  mobile-breakpoint="0"
                  hide-default-footer
                >
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td>
                        {{ props.item.po_number }}
                      </td>
                      <td>{{ props.item.invoice_number }}</td>
                      <td>{{ getDate(props.item.invoice_date) }}</td>
                      <td>{{ props.item.payment_method }}</td>
                      <td>{{ props.item.payment_date }}</td>
                      <td>{{ props.item.status }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Stok Sedikit & Stok Banyak -->
      <v-row>
        <v-col class="col-12 col-lg-6">
          <less-stok></less-stok>
        </v-col>
        <v-col class="col-12 col-lg-6">
          <much-stok></much-stok>
        </v-col>
      </v-row>

      <!-- Daftar Pegawai Toko & Statistik Penjualan -->
      <v-row v-if="monthlyProgressLoaded">
        <v-col class="col-12 col-lg-8">
          <operator-list></operator-list>
        </v-col>
        <v-col class="col-12 col-lg-4">
          <linear-statistic
            :title="currentMonth"
            sub-title="Penjualan"
            icon="mdi-trending-up"
            color="success"
            :value="monthlyProgressData.progress_penjualan"
          >
          </linear-statistic>
          <linear-statistic
            class="my-4"
            :title="currentMonth"
            sub-title="Keuntungan"
            icon="mdi-trending-up"
            color="error"
            :value="monthlyProgressData.progress_keuntungan"
          >
          </linear-statistic>
          <linear-statistic
            class="my-4"
            :title="currentMonth"
            sub-title="Pengeluaran"
            icon="mdi-trending-up"
            color="primary"
            :value="monthlyProgressData.progress_pengeluaran"
          >
          </linear-statistic>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VWidget from "@/components/VWidget";
import EChart from "@/plugins/echart";
import LessStok from "@/components/Widget/mitra/LessStok";
import MuchStok from "@/components/Widget/mitra/MuchStok";
import OperatorList from "@/components/Widget/mitra/OperatorList";
import LinearStatistic from "@/components/Widget/statistic/LinearStatistic";
import globalMixin from "@/mixins/globalMixin.js";

export default {
  components: {
    VWidget,
    EChart,
    LessStok,
    MuchStok,
    OperatorList,
    LinearStatistic
  },
  mixins: [globalMixin],

  data() {
    return {
      topTransactionLoaded: false,
      annualTransactionLoaded: false,
      monthlyProgressLoaded: false,
      penjualanHeaders: [
        { text: "Nomor Struk", sortable: false },
        { text: "Total", sortable: false },
        { text: "Metode Pembayaran", sortable: false },
        { text: "Customer", sortable: false },
        { text: "Tanggal Penjualan", sortable: false }
      ],
      purchaseBillHeaders: [
        { text: "Nomor PO", sortable: false },
        { text: "Nomor Faktur", sortable: false },
        { text: "Tanggal Faktur", sortable: false },
        { text: "Metode Pembayaran", sortable: false },
        { text: "Tanggal Pembayaran", sortable: false },
        { text: "Status", sortable: false }
      ],
      colors: {
        Cash: null,
        Ovo: "#432575",
        Gopay: "#00AA13",
        LinkAja: "#E62B1E",
        TransferBank: "#002B73"
      },
      chartColors: {
        blue: "#335EA6",
        green: "#38AC3D"
      }
    };
  },

  mounted() {
    this.getCurrentMonth();
    this.getAnnualTransaction();
    this.getTopTransaction();
    this.getMonthlyProgress();
    this.$store.dispatch("sales/getAll");
    this.$store.dispatch("purchaseBill/getAll");
  },

  computed: {
    salesData() {
      return this.$store.getters["sales/getSales"];
    },
    purchaseBillData() {
      return this.$store.getters["purchaseBill/getPurchaseBills"];
    },
    monthlyProgressData() {
      return this.$store.getters["mitraDashboard/getMonthlyProgress"];
    },
    topTransactionData() {
      return this.$store.getters["mitraDashboard/getTopTransaction"];
    },
    annualTransactionData() {
      return this.$store.getters["mitraDashboard/getAnnualTransaction"];
    }
  },

  methods: {
    getAnnualTransaction() {
      this.$store
        .dispatch("mitraDashboard/getAnnualTransaction")
        .then(() => {
          this.annualTransactionLoaded = true;
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
          this.annualTransactionLoaded = false;
        });
    },
    getTopTransaction() {
      this.$store
        .dispatch("mitraDashboard/getTopTransaction")
        .then(() => {
          this.topTransactionLoaded = true;
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
          this.topTransactionLoaded = false;
        });
    },
    getMonthlyProgress() {
      this.$store
        .dispatch("mitraDashboard/getMonthlyProgress")
        .then(() => {
          this.monthlyProgressLoaded = true;
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
          this.monthlyProgressLoaded = false;
        });
    },
    getColor(flag) {
      const color = {
        1: () => this.colors.Cash,
        2: () => this.colors.Ovo,
        3: () => this.colors.Gopay,
        4: () => this.colors.LinkAja,
        DEFAULT: () => this.colors.TransferBank
      };

      return (color[flag] || color.DEFAULT)();
    }
  }
};
</script>
