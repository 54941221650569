<template>
  <v-card>
    <v-app-bar dense color="transparent">
      <v-toolbar-title>
        <h5>Stok Sedikit</h5>
      </v-toolbar-title>
    </v-app-bar>

    <v-card-text class="pa-0">
      <template>
        <v-data-table
          :headers="headers"
          :items="lessStockList"
          class="elevation-0"
          mobile-breakpoint="0"
          hide-default-footer
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.product_name }}</td>
              <td>
                <v-chip color="error">
                  {{ props.item.stock }}
                </v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import MitraDashboardService from "@/services/MitraDashboardService";

export default {
  data() {
    return {
      lessStockList: [],
      headers: [
        { text: "Nama Produk", sortable: false },
        { text: "Jumlah", sortable: false }
      ]
    };
  },

  mounted() {
    this.getLessStock();
  },

  methods: {
    getLessStock() {
      MitraDashboardService.getLessStock()
        .then(response => {
          const data = response.data.stocks;
          this.lessStockList = data;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    }
  }
};
</script>
