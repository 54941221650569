<template>
  <v-card>
    <v-app-bar dense color="transparent">
      <v-toolbar-title>
        <h5>Daftar Operator</h5>
      </v-toolbar-title>
    </v-app-bar>

    <v-card-text class="pa-0">
      <template>
        <v-data-table
          :headers="headers"
          :items="operatorList"
          class="elevation-0"
          mobile-breakpoint="0"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.operator_name }}</td>
              <td>{{ props.item.operator_code }}</td>
              <td>
                {{ formatRupiah(props.item.monthly_total) }}
              </td>
              <td>
                {{ formatRupiah(props.item.year_total) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";
import MitraDashboardService from "@/services/MitraDashboardService";

export default {
  mixins: [globalMixin],

  data() {
    return {
      operatorList: [],
      headers: [
        { text: "Nama", sortable: false },
        { text: "Kode Operator", sortable: false },
        { text: "Penjualan Bulan Ini", sortable: false },
        { text: "Penjualan Tahun Ini", sortable: false }
      ]
    };
  },

  mounted() {
    this.getOperator();
  },

  methods: {
    getOperator() {
      MitraDashboardService.getOperator()
        .then(response => {
          const datas = response.data.operators;
          this.operatorList = datas;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    }
  }
};
</script>
